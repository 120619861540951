export function getFeeOrLimitDescription(type) {
  switch (type) {
    case 'BOLETO_MONTHLY_FREE':
      return "BOLETO grátis por mês"
    case 'BOLETO_MAX_UNPAID_DUEDATE':
      return "Dias de vencimento BOLETO"
    case 'BOLETO_MIN_VALUE':
      return "Valor mín. BOLETO"
    case 'BOLETO_MAX_VALUE':
      return "Valor máx. BOLETO"
    case 'BOLETO_MAX_VALUE_MONTHLY':
      return "Valor máx. BOLETO mês"
    case 'TRANSF_MAX_VALUE_DAILY':
      return "Valor máx. TRANSFERENCIA"
    case 'TRANSF_MAX_VALUE_MONTHLY':
      return "Valor máx. TRANSFERENCIA mês"
    case 'PRE_AGREED_TRANSF_DEPOSIT_MAX_REQUEST_DAILY':
      return "Valor máx. RECEBIMENTO dia"
    case 'PRE_AGREED_TRANSF_DEPOSIT_MIN_VALUE':
      return "Valor mín. RECEBIMENTO"
    case 'PRE_AGREED_TRANSF_DEPOSIT_MAX_VALUE':
      return "Valor máx. RECEBIMENTO"
  }
  return "..."
}
