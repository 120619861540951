<template>
  <div class="content">
    <base-header class="pb-6">
      <div class="row align-items-center py-4">
        <div class="col-lg-6 col-7">
          <h6 class="h2 text-white d-inline-block mb-0">Limites e Tarifas</h6>
        </div>
      </div>
    </base-header>
    <div class="container-fluid mt--6">
      <div>
        <div class="card">
          <div class="card-body" v-if="isNullOrEmpty(socialNumberDisplay)">
            <div class="row">
              <div class="col-md-12 text-center">
                <ul class="list-unstyled my-4">
                  <li>
                    <div class="d-flex align-items-center">
                      <div>
                        <div class="icon icon-xs icon-shape bg-gradient-primary text-white shadow rounded-circle">
                          <i class="fas fa-arrow-right"></i>
                        </div>
                      </div>
                      <div>
                        <span class="pl-2 text-md">Antes de visualizar Limites e Tarifas, é necessário verificar a conta, completando o seu cadastro.</span>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
              <div class="col-md-12 text-center">
                <router-link to="/pages/user" class="btn btn-primary">
                  <span class="btn-label"><i class="fas fa-tasks"></i> COMPLETAR CADASTRO</span>
                </router-link>
              </div>
            </div>
          </div>
          <div class="card-body" v-if="!isNullOrEmpty(socialNumberDisplay)">
            <div class="row">
              <div class="col-md-12">
                <div class="card">
                  <div class="border-0 card-header">
                    <h3 class="text-uppercase ls-1 text-primary mb-0">Confira suas tarifas:</h3>
                  </div>
                  <el-table class="table-responsive table-flush"
                            header-row-class-name="thead-light"
                            :data="fees">
                    <el-table-column label="Tarifa"
                                     min-width="290px"
                                     prop="description">
                      <template v-slot="{row}">
                        <div class="d-flex text-left">
                          <div>
                            <div class="icon icon-xs icon-shape bg-gradient-primary text-white shadow rounded-circle">
                              <i class="fas fa-receipt"></i>
                            </div>
                          </div>
                          <div>
                            <span class="pl-2 text-sm">{{row.description}}</span>
                          </div>
                        </div>
                      </template>
                    </el-table-column>
                    <el-table-column label="Valor"
                                     min-width="140px"
                                     prop="value">
                      <template v-slot="{row}">
                        <badge class="badge-dot mr-4" type="">
                          <span class="font-weight-600">R$ {{toMoney(row.value)}}</span>
                        </badge>
                      </template>
                    </el-table-column>
                  </el-table>
                </div>
              </div>
              <div class="col-md-12">
                <div class="row">
                  <div class="col-md-12">
                    <div class="card">
                      <div class="border-0 card-header">
                        <h3 class="text-uppercase ls-1 text-primary mb-0">Confira seus limites:</h3>
                      </div>
                      <el-table class="table-responsive table-flush"
                                header-row-class-name="thead-light"
                                :data="opLimits">
                        <el-table-column label="Limite"
                                         min-width="290px"
                                         prop="description">
                          <template v-slot="{row}">
                            <div class="d-flex text-left">
                              <div>
                                <div
                                  class="icon icon-xs icon-shape bg-gradient-primary text-white shadow rounded-circle">
                                  <i class="fas fa-chart-line"></i>
                                </div>
                              </div>
                              <div>
                                <span class="pl-2 text-sm">{{getFeeOrLimitDescription(row.limit)}}</span>
                              </div>
                            </div>
                          </template>
                        </el-table-column>
                        <el-table-column label="Valor"
                                         min-width="140px"
                                         prop="value">
                          <template v-slot="{row}">
                            <badge class="badge-dot mr-4" type="">
                              <span class="font-weight-600">R$ {{toMoney(row.value)}}</span>
                            </badge>
                          </template>
                        </el-table-column>

                        <el-table-column label="Utilização"
                                         min-width="170px"
                                         prop="limitUsed">
                          <template v-slot="{row}">
                            <div v-if="row.limitUsed != null" class="d-flex align-items-center">
                              <span class="completion mr-2">{{getLimitUsagePercent(row)}}%</span>
                              <div>
                                <base-progress :type="getLimitUsageStatusType(row)" :value="getLimitUsagePercent(row)"/>
                              </div>
                            </div>
                            <div v-if="row.limitUsed == null" class="d-flex align-items-center">
                              <span class="completion mr-2">N.A.</span>
                            </div>
                          </template>
                        </el-table-column>
                      </el-table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <card header-classes="bg-transparent" body-classes="px-lg-2"
                      class="card-pricing border-0 text-center">
                  <h4 slot="header" class="text-uppercase ls-1 text-primary py-3 mb-0">Solicite alteração de seus
                    limites ou tarifas:</h4>
                  <div class="row">
                    <div class="col-md-7 center">
                      <div class="card-body">
                        <ul class="list-unstyled my-0">
                          <li>
                            <div class="d-flex text-left">
                              <div>
                                <div
                                  class="icon icon-xs icon-shape bg-gradient-primary text-white shadow rounded-circle">
                                  <i class="fas fa-arrow-right"></i>
                                </div>
                              </div>
                              <div>
                                <span class="pl-2 text-sm">Clique no botão abaixo e nossa equipe entrará em contato para análise de suas necessidades.</span>
                              </div>
                            </div>
                          </li>
                        </ul>
                        <div class="row">
                          <div class="col-md-12 text-center mt-3 mb-3">
                            <button type="button" class="btn btn-primary" @click="requestTicket">
                              <span class="btn-label"><i slot="label" class="fas fa-comments-dollar"></i></span>
                              Solicitar Alteração
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </card>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

</template>
<script>
  import {Option, Select, Table, TableColumn} from 'element-ui';
  import {failWs, getWs, postWs} from '@/ws.service'
  import {isNullOrEmpty, moneyToFloat, toMoney} from "@/util/core.utils"
  import {getFeeOrLimitDescription} from '@/util/feesandlimits.utils'
  import {VMoney} from 'v-money'

  export default {
    components: {
      [Select.name]: Select,
      [Option.name]: Option,
      [Table.name]: Table,
      [TableColumn.name]: TableColumn,
    },
    data() {
      return {
        usernameDisplay: '',
        socialNumberDisplay: '',
        opLimits: [],
        fees: [],
      }
    },
    created() {
      this.usernameDisplay = "@" + localStorage.getItem("usernameDisplay")
      this.socialNumberDisplay = localStorage.getItem("socialNumberDisplay")
      console.log(this.socialNumberDisplay);
    },
    mounted() {
      if (!isNullOrEmpty(this.socialNumberDisplay)) {
        this.loadData();
      }
    },
    methods: {
      toMoney,
      moneyToFloat,
      isNullOrEmpty,
      getFeeOrLimitDescription,
      requestTicket() {
        postWs("/ticket/open", true,
          null, {
            type: 'LIMITS_FEES_UPDATE_REQUEST'
          },
          (response) => {
            if (response.data.id != null && !response.data.alreadyExists) {
              this.$swal({
                title: 'Sucesso!',
                html: '<p>Em breve um de nossos analistas financeiros entrará em contado com você.<br><br><b>Certifique-se de que seus dados para contato estão atualizados!</b></p>',
                buttonsStyling: false,
                customClass: {
                  confirmButton: 'btn btn-info btn-fill'
                },
                icon: 'success',
                showCancelButton: false,
                confirmButtonText: 'Ok'
              })
            } else if (response.data.id != null && response.data.alreadyExists) {
              this.$swal({
                title: 'Pedido já existente!',
                html: '<p>Já existe uma chamada em nosso sistema para sua solicitação. Por favor, aguarde o contato um de nossos analistas financeiros.<br><br><b>Certifique-se de que seus dados para contato estão atualizados!</b></p>',
                buttonsStyling: false,
                customClass: {
                  confirmButton: 'btn btn-info btn-fill'
                },
                icon: 'warning',
                showCancelButton: false,
                confirmButtonText: 'Ok'
              })
            } else {
              this.$swal({
                title: 'Ops!',
                html: '<p>Tivemos algum problema com seu pedido. Por favor tente novamente mais tarde!</p>',
                buttonsStyling: false,
                customClass: {
                  confirmButton: 'btn btn-info btn-fill'
                },
                icon: 'error',
                showCancelButton: false,
                confirmButtonText: 'Ok'
              })
            }
          }, failWs)
      },
      getLimitUsageStatusType(row) {
        if (this.getLimitUsagePercent(row) > 75) {
          return "danger"
        }
        return "default"
      },
      getLimitUsagePercent(row) {
        return (row.limitUsed * 100) / row.value;
      },
      loadData() {
        getWs("/op-limit/get-user-op-limits",
          true, null, this.successLoadDataLimits,
          failWs)
        getWs("/fee/list-user-fees",
          true, null, this.successLoadDataFee,
          failWs)
      },
      successLoadDataLimits(response) {
        if (response.data.personType === "LEGAL_PERSON") {
          this.opLimits = response.data.listLegalPerson;
        } else if (response.data.personType === "NATURAL_PERSON") {
          this.opLimits = response.data.listNaturalPerson
        }
      },
      successLoadDataFee(response) {
        this.fees = response.data.list;
      }
    },
    directives: {money: VMoney}
  };
</script>
<style>
  .no-border-card .card-footer {
    border-top: 0;
  }
</style>
